export default {
  common: {
    ok: 'はい',
    colon: '：',
    submit: '送信',
    resend: '再送信',
    cancel: 'キャンセル',
    confirm: '確認',
    backToTickets: 'チケット一覧に戻る',
    backToTicketDetails: 'チケット詳細に戻る',
    tickets: 'チケット一覧',
    day: ' 日',
    hour: '時間',
    min: '分',
    second: '秒',
    useChrome: '最新のChromeブラウザをご利用ください。',
    recommendBrowserTips:
      '非推奨のブラウザでアクセスしています。推奨のブラウザを利用しアクセスしてください。',
    copyBtn: 'URLをコピー',
    specialWebviewTip1:
      'ご利用中のiOS端末のバージョンでは、本チケットをご使用いただけない場合がございます。',
    specialWebviewTip2:
      'ご利用中のiOS端末を最新バージョンにアップデートしていただくか、または他の端末で本チケットをご使用ください。'
  },
  advanced: {
    title: '駅係員機能',
    success: '駅係員処理が完了しました。'
  },
  booking: {
    details: '予約詳細',
    history: '利用履歴',
    bookedDate: '予約日',
    participationLocations: '参加場所',
    participationLocationKind: {
      MAP_OF_MEETING_POINT: '場所説明',
      NOTE_OF_LOCATION_AND_DIRECTION: '場所、行き方の補足説明',
      LANDMARKS_TO_MEETING_POINT: '集合場所までのランドマーク',
      PICK_UP_CAR: '迎えに行く車',
      PICK_UP_GUIDE: '迎えに行くガイド',
      ITINERARY: 'スケジュール',
      PARKING: '駐車場について'
    },
    inclusion: '含まれるもの',
    restrictions: '参加制限',
    requirements: '参加前・参加時必要事項',
    attireItems: '参加時必要な服装・持ち物',
    otherInfo: '備考・その他',
    cancellationPolicy: 'キャンセルポリシー'
  },
  history: {
    title: '利用履歴'
  },
  otp: {
    title: 'ワンタイムパスワード',
    tips1: 'お客様のアクセス環境が変わりました。',
    tips2: 'セキュリティ保護のため、ワンタイムパスワードによる認証を行います。',
    tips3: 'ワンタイムパスワードを受け取るメールアドレスを登録してください。',
    inputOTPCode:
      'メールに記載されているワンタイムパスワードを入力してください。',
    sendCode:
      '{{email}} にワンタイムパスワードを送付しました。メールを確認してください。',
    invalidEmail: '無効なメールアドレスです。',
    inputEmail: 'メールアドレスを入力してください。',
    inputPassword: 'ワンタイムパスワードを入力してください。',
    sendOTP: 'ワンタイムパスワードを送信する'
  },
  ticket: {
    status: {
      INVALID: '無効',
      AWAITING: '利用可能期間前',
      UNUSED: '利用前',
      USABLE: '利用可能',
      IN_USE: '利用中',
      USED: '利用済み',
      EXPIRED: '期限切れ',
      DISTRIBUTED: '同行者に分配済み',
      RETURNED: '同行者への分配を返却済み'
    },
    subStatus: {
      ENTERED: '入場済み',
      EXITED: '出場済み',
      INITIAL_STATION_CANCELLED: '乗車駅キャンセル'
    },
    nowTime: '現在時刻',
    startTime: '開始時間',
    passed: '経過',
    QRCodeExpirationRemainingTime: 'QRコード有効期限残り',
    screenShotTips: 'スクリーンショットでのご利用は不可です。',
    showScreenToStaff: 'この画面をスタッフにご提示ください。',
    instruction: '利用説明',
    usedAt: '利用日',
    availablePeriod: '利用可能期間',
    bookingId: '予約番号',
    startUsing: '利用開始',
    startUsingTips: 'チケットの利用を開始しますか？',
    distributeTicket: 'チケットを分配する',
    distributeTicketTips:
      '分配ボタンよりリンクをコピーできない場合、上記のURLをコピーして同行者へ分配してください',
    reDistributeTicket: '再分配する',
    reDistributeTips: '※同行者がチケット『未使用』の場合のみ再分配はできます。',
    reDistributeTips2: '※再分配後、分配したリンクが無効になります。',
    copyLinkSuccess: 'リンクをコピーしました。',
    use: '利用',
    confirmUse: '利用内容の確認',
    useTicket: '<span>{{count}}</span>枚を利用します',
    issueTicketTips: '係員の前で利用してください',
    issueTicketNotes: '※利用後は取消不可となります',
    undo: '取り消し',
    redeem: '利用開始',
    thanks: 'ご利用ありがとうございます',
    second: '{{count}}秒',
    expirationDate: '有効期限',
    supplier: '催行会社',
    participatorName: '参加者名',
    validPeriod: '利用後有効期間',
    fixedTime: '{{day}}日間(最終日は現地時刻{{time}}まで)',
    enableCamera: 'カメラ機能を有効にしてください。'
  },
  apiErrors: {
    baseError: 'エラーが発生しました。',
    clientError: 'クライアントからのリクエストに誤りがありました。',
    serverError: 'サーバがリクエストの処理に失敗しました。',
    connectionError:
      '接続エラーが発生しました。通信環境をご確認して、再度お試しください。',
    requestError: 'リクエストの作成に誤りがありました。'
  }
};
