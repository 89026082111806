import React from 'react';
import otp, { OTP } from './otp';
import dateNow, { DateNow } from './date';

export type RootStore = {
  otp: OTP;
  dateNow: DateNow;
};

export const store: RootStore = {
  otp,
  dateNow
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export { otp };
